import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 404.000000 272.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,272.000000) scale(0.100000,-0.100000)">
     

<path d="M0 1360 l0 -1360 2020 0 2020 0 0 1360 0 1360 -2020 0 -2020 0 0
-1360z m1216 493 l98 -198 93 184 c51 101 98 190 104 197 10 12 171 14 965 14
525 0 954 -3 954 -6 0 -12 -215 -439 -225 -446 -5 -4 -121 -8 -257 -8 l-247 0
-32 -65 c-18 -35 -36 -62 -41 -59 -4 3 -6 -1 -3 -8 7 -17 -69 -161 -79 -151
-4 4 -5 1 -1 -7 6 -18 -68 -163 -79 -153 -4 4 -5 1 -1 -7 6 -18 -68 -163 -79
-153 -4 4 -5 1 -1 -7 6 -18 -68 -163 -79 -153 -4 4 -5 1 -1 -7 3 -8 -11 -45
-31 -83 l-35 -67 -261 0 c-143 0 -258 3 -256 8 2 4 103 206 226 450 122 243
222 447 222 452 0 6 -72 10 -184 10 l-184 0 -238 -480 c-132 -263 -243 -478
-248 -476 -7 2 -713 1397 -716 1414 0 1 116 2 259 2 l258 0 99 -197z"/>
<path d="M1032 1405 l283 -565 210 420 210 420 297 0 c164 0 298 -2 298 -5 0
-3 -104 -212 -230 -465 l-230 -460 150 0 150 0 232 465 233 465 252 0 253 0
70 140 c38 77 70 142 70 145 0 3 -381 5 -848 5 l-847 0 -135 -270 -135 -270
-135 270 -135 270 -147 0 -148 0 282 -565z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
